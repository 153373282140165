var liveVideo = {
  videoPlayerConfig: {
    videoType: 'live',
    loggedIn: false,
    hasCredit: false,
    alreadyCharged: false,
    autoplay: true,
    fileId: 'hHGSt1tz',
    locale: {
      language: 'cz',
      loadPath: 'http://private-c811a4-ulozto1.apiary-mock.com/locale/en',
      addPath: 'http://localhost:3000/ahoj',
    },
    videoElement: document.getElementById('root'),
    slideshow: document.getElementById('slideshow'),
    subtitlesURL: 'http://private-c811a4-ulozto1.apiary-mock.com/get-subtitles',
    sources: [
      {
        quality: '360p',
        URL: 'https://thv1.uloz.to/e/b/6/eb6fed84301f4de89dee8328c6c4fe94.360.mp4?fide=4zVTNo9&fileId=296292409&vt=1580425199&sg=XD_1y6P24Rj1mgs3_w11FA&bl=',
      },
      {
        quality: '720p',
        URL: 'https://thv1.uloz.to/e/b/6/eb6fed84301f4de89dee8328c6c4fe94.360.mp4?fide=4zVTNo9&fileId=296292409&vt=1580425199&sg=XD_1y6P24Rj1mgs3_w11FA&bl=',
      },
    ],
  },
  ads: {
    vastUrl: 'https://bs.serving-sys.com/Serving?cn=display&c=23&pl=VAST&pli=22776387&PluID=0&pos=1198&ord=[timestamp]&cim=1',
  },
}

var paidVideo = {
  videoPlayerConfig: {
    videoType: 'withoutPreview',
    loggedIn: true,
    hasCredit: true,
    alreadyCharged: true,
    autoplay: false,
    fileId: 'hHGSt1tz',
    locale: {
      language: 'cs',
      loadPath: 'http://private-c811a4-ulozto1.apiary-mock.com/locale/cs',
      addPath: 'http://localhost:3000/ahoj',
    },
    slideshow: document.getElementById('slideshow'),
    videoElement: document.getElementById('root'),
    subtitlesURL: 'http://private-c811a4-ulozto1.apiary-mock.com/get-subtitles',
    sources: [
      {
        quality: '360p',
        URL: 'https://thv1.uloz.to/e/b/6/eb6fed84301f4de89dee8328c6c4fe94.360.mp4?fide=4zVTNo9&fileId=296292409&vt=1580425199&sg=XD_1y6P24Rj1mgs3_w11FA&bl=',
      },
    ],
  },
  charge: {
    fileSize: '235MB',
    getCheckURL: 'http://private-c811a4-ulozto1.apiary-mock.com/check-availability',
    watchRefreshURL: 'http://private-c811a4-ulozto1.apiary-mock.com/watch-refresh',
    watchExpire: 1577148184730,
  },
  ads: {
    vastUrl: 'https://bs.serving-sys.com/Serving?cn=display&c=23&pl=VAST&pli=22776387&PluID=0&pos=1198&ord=[timestamp]&cim=1',
  },
  actions: {
    onLogIn: () => alert('Přihlašuji...'),
    onLowCredit: () => alert('Nedostatek kreditu'),
    onRechargeCredit: () => alert('Dobíjím kredit...'),
  },
}

var videoWithPreview = {
  videoPlayerConfig: {
    videoType: 'withPreview',
    loggedIn: true,
    hasCredit: true,
    alreadyCharged: false,
    autoplay: false,
    fileId: 'hHGSt1tz',
    locale: {
      language: 'cz',
      loadPath: 'http://private-c811a4-ulozto1.apiary-mock.com/locale/en',
      addPath: 'http://localhost:3000/ahoj',
    },
    posters: [
      'https://carepharmaceuticals.com.au/wp-content/uploads/sites/19/2018/02/placeholder-600x400.png',
      'https://getuikit.com/v2/docs/images/placeholder_600x400.svg',
      'https://thousanddays.org/wp-content/uploads/issue-placeholder-thumbnail.jpg',
    ],
    videoElement: document.getElementById('root'),
    slideshow: document.getElementById('slideshow'),
    subtitlesURL: 'http://private-c811a4-ulozto1.apiary-mock.com/get-subtitles',
    sources: [
      {
        quality: '360p',
        URL: 'https://thv1.uloz.to/e/b/6/eb6fed84301f4de89dee8328c6c4fe94.360.mp4?fide=4zVTNo9&fileId=296292409&vt=1580425199&sg=XD_1y6P24Rj1mgs3_w11FA&bl=',
      },
    ],
  },
  charge: {
    fileSize: '235MB',
    getCheckURL: 'http://private-c811a4-ulozto1.apiary-mock.com/check-availability',
    watchRefreshURL: 'http://private-c811a4-ulozto1.apiary-mock.com/watch-refresh',
    watchExpire: 200000,
  },
  preview: {
    fileSize: '20MB',
    getPreviewURL: 'http://private-c811a4-ulozto1.apiary-mock.com/get-preview-url',
  },
  ads: {
    vastUrl: 'https://bs.serving-sys.com/Serving?cn=display&c=23&pl=VAST&pli=22776387&PluID=0&pos=1198&ord=[timestamp]&cim=1',
  },
  actions: {
    onLogIn: () => alert('Přihlašuji...'),
    onLowCredit: () => alert('Nedostatek kreditu'),
    onRechargeCredit: () => alert('Dobíjím kredit...'),
  },
  monitoring: {
    previewStartUrl: 'http://private-c811a4-ulozto1.apiary-mock.com/preview-started',
  },
}

const config = videoWithPreview

module.exports = config
